<template>
    <div class="login" v-loading="loading" element-loading-text="登录校验中"
    element-loading-background="rgba(0, 0, 0, 0.6)">
        <!-- <span class="title-name">保康县数字乡村</span> -->
        <div class="form-box">
            <!-- <span style="color: #ffc748;font-size: .3rem;">欢&nbsp;迎&nbsp;登&nbsp;录</span> -->
            <div class="login-txt-img">
                <img src="../assets/img/login/login-txt.png" alt="">
            </div>
            <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm" hide-required-asterisk>
                <el-form-item prop="username">
                    <img src="../assets/img/login/user.png">
                    <span>用户名</span>
                    <el-input v-model="ruleForm.username" placeholder="请输入用户名" value="username"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <img src="../assets/img/login/password.png">
                    <span>密 码</span>
                    <el-input v-model="ruleForm.password" type="password" placeholder="请输入密码"
                        value="password"></el-input>
                </el-form-item>
                <div class="rememberMe">
                    <img :src="rememberMe ? require('../assets/img/login/rember-s.png') : require('../assets/img/login/rember.png')"
                        alt=""  @click="rememberMe = !rememberMe">
                    <span>记住密码</span>
                </div>
                <el-form-item style="width:100%;">
                    <el-button size="medium" type="primary" style="width:100%;"
                        @click.native.prevent="submitForm">
                        <span>登&nbsp;&nbsp;录</span>
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { login, checkToken } from '@/api/login'
import Cookies from "js-cookie";
import { encrypt, decrypt } from '@/util/jsencrypt'
import { getUserInfo } from '@/util/common'
import Base64 from '@/util/base';

export default {
    data() {
        return {
            ruleForm: {
                username: '',
                password: ''
            },
            loading: false,
            rememberMe: false,
            rememberMeUrl: require('../assets/img/login/rember.png')
        }
    },
    created() {
		let that = this
        if (this.$route.query.token) {
			this.loading = true
            checkToken(this.$route.query.token).then(res => {
				that.loading = false
                if (res.active) {
                    let expires = new Date((res.exp - 3600) * 1000)
                    Cookies.set("Admin-Token", this.$route.query.token, { expires: expires })
                    getUserInfo()
                }
            })
        } else {
            this.getCookie();
        }

        document.onkeydown = function (e) {
            var ev = document.all ? window.event : e;
            if (ev.keyCode == 13) {
                that.submitForm();
            }
        }
    },
    methods: {
        submitForm() {
            if (this.ruleForm.username == '') {
                this.$message.error('请输入用户名！');
                return
            } else if (this.ruleForm.password == '') {
                this.$message.error('请输入密码！');
                return
            }
            var base = new Base64()
            var psd = base.encode(this.ruleForm.password)
            var param = {
                username: this.ruleForm.username,
                password: psd //sha1(this.v_login_pwd + "sessionSALT"),
            };
            login(param).then(res => {
                if (res.code == 200) {
                    this.$store.commit('changeActive', 1)
                    if (this.rememberMe) {
                        Cookies.set("username", this.ruleForm.username, { expires: 30 });
                        Cookies.set("password", psd, { expires: 30 });
                        Cookies.set('rememberMe', this.rememberMe, { expires: 30 });
                    } else {
                        Cookies.remove("username");
                        Cookies.remove("password");
                        Cookies.remove('rememberMe');
                    }
                    let seconds = res.data.expires_in - 3600;
                    let expires = new Date(new Date() * 1 + seconds * 1000);
                    Cookies.set("Admin-Token", res.data.access_token, { expires: expires })
                    Cookies.set("Refresh_Token", res.data.refresh_token, 3)
                    getUserInfo()
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        // 读取cookie
        getCookie() {
            const username = Cookies.get("username");
            const password = Cookies.get("password");
            const rememberMe = Cookies.get('rememberMe')
            var base = new Base64()
            this.ruleForm = {
                username: username === undefined ? this.ruleForm.username : username,
                password: password === undefined ? this.ruleForm.password : base.decode(password)
            };
            rememberMe === undefined ? this.rememberMe = false : this.rememberMe = Boolean(rememberMe)
        }
    }
};
</script>

<style>
input::-webkit-input-placeholder {
        font-size: 0.14rem;
}
.login .el-form-item {
    width: 4.5rem;
    height: .68rem;
    background-image: url(../assets/img/login/input-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
}

.login .el-form-item__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 !important;
}

.login .el-form-item__content img {
    width: .3rem;
    height: .3rem;
    margin-left: .3rem;
}

.login .el-form-item__content span {
    color: #fff;
    display: inline-block;
    width: .72rem;
    font-size: .2rem;
    margin-left: .1rem;
}

.login .el-input {
    width: 60%;
    background-color: transparent;
}

.login .el-input__inner {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: .18rem;
}

.login .el-form-item:last-child {
    background-image: url(../assets/img/login/buttom.png);
    margin-bottom: .5rem;
}

.login .el-button {
    background-color: transparent;
    border: none;
}

.login .el-button span {
    margin: 0 !important;
    color: #fff;
}

.el-input>.el-input__inner {
    border: none;
    line-height: 38px;
    padding-left: 10px;
    padding-right: 0;
    background: transparent;
    color: #fff
}

.el-input>.el-input__inner:-webkit-autofill,
.el-input>.el-input__inner:-webkit-autofill:hover,
.el-input>.el-input__inner:-webkit-autofill:focus,
.el-input>.el-input__inner:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
    -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
    color: #fff;
}
</style>

<style lang="scss" scoped>
.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../assets/img/login/bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;

    .form-box {
        width: 5.6rem;
        height: 5.3rem;
        background-image: url(../assets/img/login/login-bg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .login-txt-img{
        margin-bottom: 0.2rem;
    }
    .login-txt-img img{
        width:2.8rem;
    }
    .form-box>span {
        font-size: .3rem;
        color: #1bd6fa;
        height: 1.24rem;
        display: flex;
        align-items: center;
    }
}

.rememberMe {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    img {
        width: .22rem;
        height: .22rem;
    }

    span {
        color: #fff;
        font-size: .18rem;
        margin-left: .14rem;
    }

    margin-bottom: .3rem;
}

.title-name {
    position: absolute;
    font-size: .3rem;
    top: .1rem;
    color: #fff;
}
</style>